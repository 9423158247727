import axios from "axios";

export const getSolutionMetaData = async () => {
  try {
    const response = await axios.get(`/solutionCloneMetaData/`);

    return response.data.map((solution) => ({
      id: solution.solutionId,
      formattedName: solution.formattedName,
      isPublic: solution.isPublic,
      isFeatured: solution.isFeatured,
      username: solution.username,
      summary: solution.summary,
      imageUrl: solution.imageUrl,
      category: solution.category,
    }));
  } catch (error) {
    console.error("Error fetching solution metadata:", error);
    throw new Error("Failed to retrieve solution metadata");
  }
};
