// IMPORTANT: The store key for this slice is guaranteed to be projectModalState. You should use this when accessing state related to this slice e.g. state.projectModalState

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectModalVisibility: false,
  selectedProjectData: null,
  selectedProjectScreenshot: null,
};

const projectModalSlice = createSlice({
  name: "projectModalState",
  initialState,
  reducers: {
    setProjectModalVisibility: (state, action) => {
      console.log("Setting modal visibility to:", action.payload);
      state.projectModalVisibility = action.payload;
    },
    setSelectedProjectData: (state, action) => {
      console.log("Setting selected project data:", action.payload);
      // Validate the project data structure
      if (action.payload !== null) {
        const { id, formattedName, summary, category, username } =
          action.payload;
        if (
          typeof id !== "string" ||
          typeof formattedName !== "string" ||
          typeof summary !== "string" ||
          typeof category !== "string" ||
          (username !== null && typeof username !== "string")
        ) {
          console.error("Invalid project data structure");
          return;
        }
      }
      state.selectedProjectData = action.payload;
    },
    setSelectedProjectScreenshot: (state, action) => {
      console.log("Setting selected project screenshot URL:", action.payload);
      state.selectedProjectScreenshot = action.payload;
    },
  },
});

// Export actions for use in components
export const {
  setProjectModalVisibility,
  setSelectedProjectData,
  setSelectedProjectScreenshot,
} = projectModalSlice.actions;

// Export the reducer to be used in the store
export const projectModalReducer = projectModalSlice.reducer;
