import React, { useEffect } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export default function SearchIcon_Component() {
  useEffect(() => {
    // Debug logging for component mount
    console.log("SearchIcon_Component mounted");
  }, []);

  return (
    <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
      <MagnifyingGlassIcon
        className="w-5 h-5 text-gray-400"
        aria-hidden="true"
      />
    </div>
  );
}
