// ["ReduxStore", "Store"]
import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./GlobalSlice_Store.jsx";
import searchReducer from "./SearchSlice_Store.jsx";
import { projectModalReducer } from "./ModalSlice_Store.jsx";

// IMPORTANT: imports for all slices go here
import modalVisibilityReducer from "./ModalVisibilityState_Store"; // Importing ModalVisibilityState slice
import signUpFormReducer from "./SignUpFormState_Store"; // Importing SignUpFormState slice

const store = configureStore({
  reducer: {
    global: globalReducer, // Debug: GlobalSlice reducer with store key 'global'
    search: searchReducer, // Debug: SearchSlice reducer with store key 'search'
    projectModalState: projectModalReducer, // Debug: ModalSlice reducer with store key 'projectModalState'
    modalVisibility: modalVisibilityReducer,
    SignUpFormState: signUpFormReducer,
  },
});

export default store;
