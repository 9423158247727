import React from "react";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { setProjectModalVisibility } from "./ModalSlice_Store.jsx";

export default function ProjectDetailModal_Component() {
  const dispatch = useDispatch();

  // Individual selectors for better performance
  const isVisible = useSelector(
    (state) => state.projectModalState?.projectModalVisibility
  );
  const projectData = useSelector(
    (state) => state.projectModalState?.selectedProjectData
  );
  const projectScreenshot = useSelector(
    (state) => state.projectModalState?.selectedProjectScreenshot
  );

  // Handle modal close
  const handleClose = () => {
    console.log("Closing project detail modal");
    dispatch(setProjectModalVisibility(false));
  };

  return (
    <Dialog open={isVisible} onClose={handleClose} className="relative z-50">
      {/* Modal backdrop */}
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

      {/* Modal positioning */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="relative w-full max-w-lg bg-white rounded-lg shadow-sm p-6">
          {/* Close button */}
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>

          {/* Project content */}
          {projectData && (
            <div className="space-y-4">
              {/* Project screenshot */}
              <div className="w-full aspect-[16/9] rounded-lg overflow-hidden bg-gray-100">
                <img
                  src={
                    projectScreenshot ||
                    "https://placehold.co/600x400/red/white?text=' '"
                  }
                  alt={projectData.formattedName}
                  className="w-full h-full object-cover"
                />
              </div>

              {/* Project details */}
              <h2 className="text-2xl font-bold text-gray-900">
                {projectData.formattedName}
              </h2>

              <p className="text-base text-gray-600">{projectData.summary}</p>

              <p className="text-sm text-gray-500">{projectData.category}</p>

              {/* Build on this button */}
              <button
                className="w-full bg-gray-700 hover:bg-gray-600 text-white px-6 py-2 rounded-lg transition-colors font-medium"
                onClick={() =>
                  window.open(
                    "https://alpha.theorigin.ai/solutions/cloning?clonedSolutionId=" +
                      projectData.id,
                    "_blank"
                  )
                }
              >
                Build on this
              </button>
            </div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
