import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function ClearIcon_Component({ resetSearch }) {
  // Handler for click event
  const handleClear = () => {
    console.log("Clear icon clicked - triggering reset action");
    resetSearch();
  };

  return (
    <button
      onClick={handleClear}
      className="absolute right-2 p-1 text-gray-700 hover:text-gray-900 transition-colors cursor-pointer"
      aria-label="Clear search"
    >
      <XMarkIcon className="h-5 w-5" />
    </button>
  );
}
