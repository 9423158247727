import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchQuery,
  filterProjects,
  resetSearch,
} from "./SearchSlice_Store.jsx";
import { globalLoadProjectData } from "./GlobalSlice_Store.jsx";
import {
  setProjectModalVisibility,
  setSelectedProjectData,
  setSelectedProjectScreenshot,
} from "./ModalSlice_Store.jsx";
import CardAnimationComponent from "./CardAnimationComponent_Component.jsx";
import ProjectDetailModal from "./ProjectDetailModal_Component.jsx";
import SearchIcon from "./SearchIcon_Component.jsx";
import ClearIcon from "./ClearIcon_Component.jsx";
import ProjectScreenshot1 from "./ProjectScreenshot1_Image.jpg";
import ProjectScreenshot2 from "./ProjectScreenshot2_Image.jpg";
import ProjectScreenshot3 from "./ProjectScreenshot3_Image.jpg";
import ProjectScreenshot4 from "./ProjectScreenshot4_Image.jpg";
import ProjectScreenshot5 from "./ProjectScreenshot5_Image.jpg";
import ProjectScreenshot6 from "./ProjectScreenshot6_Image.jpg";

const defaultScreenshots = [
  {
    src: ProjectScreenshot1,
    alt: "Project screenshot with modern design and layout example and features",
  },
  {
    src: ProjectScreenshot2,
    alt: "Project screenshot with modern design elements and a clean interface",
  },
  {
    src: ProjectScreenshot3,
    alt: "Modern project screenshot with contemporary design features and a clean interface",
  },
  {
    src: ProjectScreenshot4,
    alt: "project screenshot with modern design and layout elements",
  },
  {
    src: ProjectScreenshot5,
    alt: "AI-generated project screenshot with modern design and features no less than 10 words",
  },
  {
    src: ProjectScreenshot6,
    alt: "Modern project screenshot with contemporary design features and elements",
  },
];

const getDefaultScreenshot = (project) => {
  if (project?.imageUrl) {
    return {
      src: project.imageUrl,
      alt: `${project.formattedName || "Project"} screenshot`,
    };
  }

  return defaultScreenshots[
    Math.floor(Math.random() * defaultScreenshots.length)
  ];
};

export default function TheOriginalsPage_Component() {
  const dispatch = useDispatch();

  const searchQuery = useSelector(
    (state) => state.search?.searchSliceSearchQuery || ""
  );
  const filteredResults = useSelector((state) =>
    Array.isArray(state.search?.searchSliceFilteredResults)
      ? state.search.searchSliceFilteredResults
      : []
  );
  const projectData = useSelector((state) =>
    Array.isArray(state.global?.globalProjectData)
      ? state.global.globalProjectData
      : []
  );
  const isLoading = useSelector(
    (state) => state.global?.globalProjectDataLoading || false
  );
  const error = useSelector(
    (state) => state.global?.globalProjectDataError || ""
  );

  useEffect(() => {
    console.log("Loading initial project data");
    dispatch(globalLoadProjectData());
  }, [dispatch]);

  const handleSearch = (e) => {
    const query = e.target.value;
    console.log("Search query updated:", query);
    dispatch(setSearchQuery(query));
    dispatch(filterProjects(query));
  };

  const handleCardClick = (project) => {
    console.log("Project card clicked:", project);
    const screenshot = getDefaultScreenshot(project);
    console.log("Selected screenshot:", screenshot);
    dispatch(setSelectedProjectData(project));
    dispatch(setSelectedProjectScreenshot(screenshot.src));
    dispatch(setProjectModalVisibility(true));
  };

  if (isLoading) {
    return (
      <div className="w-full min-h-screen bg-gray-900 px-8 py-6 flex items-center justify-center">
        <div className="text-white">Loading projects...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full min-h-screen bg-gray-900 px-8 py-6 flex items-center justify-center">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen px-8 py-6 flex flex-col gap-6">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-[60px] not-italic font-normal leading-[72px] tracking-[1.2px] text-4xl font-bold tracking-tight text-white sm:text-6xl">
          Originals
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-300">
          Speed up your process by using one of the Origin community templates
          as a starting point
        </p>
      </div>
      <div className="w-full px-8 py-4 z-10">
        <div className="max-w-6xl mx-auto w-full">
          <div className="relative">
            <SearchIcon />
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search The Originals..."
              className="w-full pl-10 pr-12 py-3 bg-white rounded-lg shadow-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
            {searchQuery && (
              <ClearIcon resetSearch={() => dispatch(resetSearch())} />
            )}
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto w-full pt-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {(searchQuery ? filteredResults : projectData).map((project) => {
            const screenshot = getDefaultScreenshot(project);
            console.log("Screen shot is: ", screenshot);
            return (
              <CardAnimationComponent
                key={project.id}
                project={project}
                onClick={() => handleCardClick(project)}
              >
                <div className="bg-gray-800 rounded-lg p-4 transform transition-all duration-200 hover:scale-105 hover:shadow-lg relative">
                  <div className="relative h-48 mb-4 overflow-hidden rounded-t-lg">
                    <img
                      src={screenshot.src}
                      alt={screenshot.alt}
                      className="absolute w-full h-48 object-cover rounded-t-lg opacity-90 hover:opacity-100 transition-opacity duration-200"
                    />
                  </div>
                  <h3 className="text-white text-lg font-medium mb-1">
                    {project.formattedName}
                  </h3>
                  {project.username && (
                    <p className="text-xs text-gray-400 mt-0.5 mb-2">
                      by {project.username}
                    </p>
                  )}
                  <p className="text-gray-400 text-sm mb-4">
                    {project.summary}
                  </p>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-400 text-xs">
                      {project.category}
                    </span>
                  </div>
                </div>
              </CardAnimationComponent>
            );
          })}
        </div>
      </div>
      <ProjectDetailModal />
    </div>
  );
}
