import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App_Component from "./App_Component.jsx";
import { MixpanelProvider } from "react-mixpanel-browser";
import axios from "axios";

const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;
axios.defaults.baseURL = import.meta.env.VITE_OPENAPI_URL;

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <MixpanelProvider
      token={mixpanelToken}
      config={{
        cookie_domain: import.meta.env.VITE_COOKIE_DOMAIN, // Enables cross-subdomain tracking
      }}
    >
      <App_Component />
    </MixpanelProvider>
  </React.StrictMode>
);
