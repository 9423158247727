import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// IMPORTANT: The store key for this slice is the same as the name: search. You should use this when accessing state related to this slice.

const initialState = {
  searchSliceSearchQuery: "",
  searchSliceFilteredResults: [],
};

/**
 * Filter projects based on search query
 * @param {string} searchQuery - The search query to filter projects
 */
export const filterProjects = createAsyncThunk(
  "search/filterProjects",
  async (searchQuery, { getState, rejectWithValue }) => {
    try {
      // Validate search query parameter
      if (typeof searchQuery !== "string") {
        console.error(
          "Invalid search query type. Expected string, got:",
          typeof searchQuery
        );
        throw new Error("Search query must be a string");
      }

      console.log("Filtering projects with query:", searchQuery);

      const globalState = getState().global;
      const projects = globalState.globalProjectData;

      // Validate project data structure
      const isValidData = projects.every((project) => {
        return (
          typeof project.id === "string" &&
          typeof project.formattedName === "string" &&
          typeof project.summary === "string" &&
          typeof project.category === "string" &&
          typeof project.username === "string"
        );
      });

      if (!isValidData) {
        console.error("Invalid project data structure");
        throw new Error("Invalid project data structure");
      }

      // Filter projects based on search query
      const filteredProjects = projects.filter((project) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          project.formattedName.toLowerCase().includes(searchLower) ||
          project.summary.toLowerCase().includes(searchLower) ||
          project.category.toLowerCase().includes(searchLower)
        );
      });

      console.log("Found filtered projects:", filteredProjects.length);
      return filteredProjects;
    } catch (error) {
      console.error("Error filtering projects:", error);
      return rejectWithValue(error.message);
    }
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      console.log("Setting search query:", action.payload);
      state.searchSliceSearchQuery = action.payload;
    },
    setFilteredResults: (state, action) => {
      console.log("Updating filtered results:", action.payload);
      state.searchSliceFilteredResults = action.payload;
    },
    resetSearch: (state) => {
      console.log("Resetting search state");
      state.searchSliceSearchQuery = "";
      state.searchSliceFilteredResults = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(filterProjects.fulfilled, (state, action) => {
        console.log("Successfully filtered projects");
        state.searchSliceFilteredResults = action.payload;
      })
      .addCase(filterProjects.rejected, (state, action) => {
        console.error("Failed to filter projects:", action.payload);
        state.searchSliceFilteredResults = [];
      });
  },
});

export const { setSearchQuery, setFilteredResults, resetSearch } =
  searchSlice.actions;

export default searchSlice.reducer;
