import React, { useState, useEffect, useRef } from "react";
import { fireTwitterEvent } from "./twitterUtil";
import { PaperAirplaneIcon } from "@heroicons/react/20/solid";
import IncubatorAvatar from "./assets/incubatorLiveIcon.png";
import { startSolution } from "./network/clients/solutions";
import { useMixpanel } from "react-mixpanel-browser";
import TypingIndicator from "./TypingIndicator"; // Your existing TypingIndicator component
import { getResponse } from "./network/clients/conversation";
import { useSearchParams } from "react-router-dom";

export default function HeadingSection_Component() {
  const mixpanel = useMixpanel(); // Access Mixpanel instance
  const [searchParams] = useSearchParams();
  const version = searchParams.get("version") || 1; // Default fallback

  const headingVersions = {
    1: {
      title: "What app shall we build together?",
      subtitle:
        "Origin is the world's first full AI product team - no technical knowledge required.",
    },
    2: {
      title: "What character shall we build?",
      subtitle:
        "Enter an initial idea below, then see your character ai built as custom software that you can share, build on and monetize - start for free!",
    },
  };

  const placeholderVersions = {
    1: {
      placeholder:
        "Briefly describe the app you want to build, or business problem you're hoping to solve...",
    },
    2: {
      placeholder:
        "To start brainstorming free, briefly describe the character you'd like to generate...",
    },
  };

  const prompts = [
    {
      version: "2",
      prompt:
        "An Einstein themed character chatbot that helps with homework...",
    },
    {
      version: "2",
      prompt:
        "A napolean themed character chatbot that likes to comment on modern history in a comic fashion...",
    },
    {
      version: "2",
      prompt:
        "Create me a Gojo Satoru themed character chatbot that can chat with me...",
    },
    {
      version: "1",
      prompt: "Build me a simple CRM for my auto sales team...",
    },
    {
      version: "1",
      prompt:
        "I want a page that generates mockups for iOS chat applications...",
    },
    {
      version: "1",
      prompt: "Build me a property sharing website like Airbnb...",
    },
    {
      version: "1",
      prompt: "An app that generates marketing emails for small businesses...",
    },
    {
      version: "1",
      prompt: "A time tracking app for my 4 person remote design agency...",
    },
    {
      version: "1",
      prompt:
        "A quick way to keep track of my investor leads with fields for how I was introduced and why I think they are interesting...",
    },
  ];

  const { title, subtitle } = headingVersions[version] || headingVersions["1"];

  const [prompt, setPrompt] = useState("");
  const [index, setIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [chatMode, setChatMode] = useState(false);
  const [messages, setMessages] = useState([
    {
      content: "Hello! I'm Origin AI. What app shall we build together?",
      role: "assistant",
    },
  ]);
  const [isAwaitingSolution, setIsAwaitingSolution] = useState(false);
  const messagesEndRef = useRef(null);
  const [solutionId, setSolutionId] = useState(null);

  useEffect(() => {
    mixpanel.track("Page Viewed", { page: "Home" });
  }, []);

  // TYPED PROMPTS (header text) -----------------------------
  useEffect(() => {
    if (isUserTyping || chatMode) return;

    const filteredPrompts = prompts.filter(
      (p) => p.version === String(version)
    );

    if (filteredPrompts.length === 0) return;

    let currentPrompt = filteredPrompts[index % filteredPrompts.length].prompt;

    let i = 0;
    const interval = setInterval(() => {
      setDisplayText(currentPrompt.slice(0, i));
      if (i <= currentPrompt.length) {
        i++;
      } else {
        clearInterval(interval);
        setTimeout(() => {
          setIndex((prevIndex) => (prevIndex + 1) % filteredPrompts.length);
        }, 1500);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [index, isUserTyping, chatMode, version]);

  // AUTO-SCROLL MESSAGES -------------------------------------
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [messages, isAwaitingSolution]);

  // STREAM TEXT FUNCTION --------------------------------------
  function streamText(fullText, onUpdate, onComplete) {
    let i = 0;
    const intervalId = setInterval(() => {
      onUpdate(fullText.slice(0, i));
      i++;
      if (i > fullText.length) {
        clearInterval(intervalId);
        onComplete();
      }
    }, 20);
  }

  // HANDLE ENTER/SHIFT+ENTER ----------------------------------
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handlePromptSubmit(event);
    }
  };

  // SUBMIT PROMPT ---------------------------------------------
  const handlePromptSubmit = async (event) => {
    event.preventDefault();
    if (!prompt.trim()) return;
    setChatMode(true);

    // Track "Message Submitted" with user content
    mixpanel.track("Message Submitted", { message: prompt });

    // Add user message
    let newMessages = [...messages, { content: prompt, role: "user" }];
    setMessages(newMessages);
    setIsAwaitingSolution(true);

    const userPrompt = prompt;
    setPrompt("");

    let newSolutionId = solutionId;
    if (!solutionId) {
      const mixpanelId = mixpanel.get_distinct_id();
      newSolutionId = await startSolution(mixpanelId, userPrompt);
      setSolutionId(newSolutionId);
    }

    // Get response from the server
    let response = await getResponse(newSolutionId, newMessages, version);

    // Hide typing indicator as soon as we start streaming
    setIsAwaitingSolution(false);

    // Decide if we show the CTA or do streaming
    if (response.includes("READY TO GO")) {
      // Insert a special CTA message
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          isCtaMessage: true,
          content:
            "<p>OK, we're ready to get going for free.</p><p>Sign up, and in the background I'll take your idea and start building it.</p><p>See you on the other side!</p>",
        },
      ]);
      // Track "Home page sign up button shown"
      mixpanel.track("Home page sign up button shown");
    } else {
      // Insert an empty assistant message
      setMessages((prev) => [...prev, { content: "", role: "assistant" }]);

      // Animate the streaming text
      streamText(
        response,
        (partialText) => {
          setMessages((prev) => {
            const updated = [...prev];
            updated[updated.length - 1].content = partialText;
            return updated;
          });
        },
        () => {
          // No-op after streaming completes
        }
      );
    }
  };

  // -----------------------------------------------------------
  return (
    <div className="bg-transparent px-6 py-20 sm:py-20 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-[60px] not-italic font-normal leading-[72px] tracking-[1.2px] text-4xl font-bold tracking-tight text-white sm:text-6xl">
          {title}
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-300">{subtitle}</p>

        {/* Chat window */}
        {chatMode ? (
          <div className="mt-8 w-full max-w-2xl mx-auto bg-gray-800 p-4 rounded-lg text-white flex flex-col gap-4">
            <ul
              className="h-48 overflow-y-auto flex flex-col gap-4"
              style={{ WebkitOverflowScrolling: "touch" }}
            >
              {messages.slice(1).map((msg, idx) => (
                <li key={idx} className="flex items-start gap-4">
                  {msg.role === "user" ? (
                    <div className="min-w-10 min-h-10 w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center">
                      <span className="text-white font-bold text-sm leading-none">
                        U
                      </span>
                    </div>
                  ) : (
                    <img
                      className="w-10 h-10 rounded-full"
                      alt="Origin"
                      src={IncubatorAvatar}
                    />
                  )}
                  <div
                    className={`p-2 rounded ${
                      msg.role === "user"
                        ? "bg-blue-500 text-right"
                        : "bg-gray-700 text-left"
                    }`}
                  >
                    {msg.isCtaMessage ? (
                      <div className="space-y-2 text-left">
                        <p
                          dangerouslySetInnerHTML={{ __html: msg.content }}
                        ></p>
                        <a
                          onClick={() => {
                            // Track "Home page sign up button clicked"
                            mixpanel.track("Home page sign up button clicked");
                            fireTwitterEvent();
                          }}
                          href={
                            import.meta.env.VITE_SIGNUP_PAGE +
                            "?solutionId=" +
                            solutionId
                          }
                          rel="noreferrer"
                          className="inline-flex items-center gap-2 px-4 py-2 bg-[#E64428] text-white rounded-md hover:bg-[#cc3922]"
                        >
                          Sign up for free
                        </a>
                      </div>
                    ) : (
                      msg.content
                    )}
                  </div>
                </li>
              ))}
              {isAwaitingSolution && (
                <li className="flex items-start gap-4">
                  <img
                    className="w-10 h-10 rounded-full"
                    alt="Origin"
                    src={IncubatorAvatar}
                  />
                  <div className="p-2 rounded bg-gray-700 text-left">
                    <TypingIndicator />
                  </div>
                </li>
              )}
              <div ref={messagesEndRef} />
            </ul>
            <form
              className="flex items-center relative"
              onSubmit={handlePromptSubmit}
            >
              <textarea
                value={prompt}
                onChange={(e) => {
                  // If user is actually typing for the first time, track "Started Chat"
                  if (!isUserTyping && e.target.value) {
                    mixpanel.track("Started Chat");
                    setIsUserTyping(true);
                  }
                  setPrompt(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                placeholder="Type your response..."
                className="w-full h-20 bg-gray-900 text-gray-400 px-4 py-2 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 resize-none pr-10"
              />
              <button
                type="submit"
                className="absolute bottom-4 right-4 bg-[#E64428] text-white p-2 rounded-md hover:bg-[#cc3922] focus:outline-none focus:ring-2 focus:ring-[#cc3922]"
              >
                <PaperAirplaneIcon className="w-5 h-5" />
              </button>
            </form>
          </div>
        ) : (
          <div className="mt-8 flex flex-col justify-center items-center w-full max-w-2xl mx-auto p-4 relative">
            <textarea
              value={isUserTyping ? prompt : displayText}
              onChange={(e) => {
                // If user is actually typing for the first time, track "Started Chat"
                if (!isUserTyping && e.target.value) {
                  mixpanel.track("Started Chat");
                  setIsUserTyping(true);
                }
                setPrompt(e.target.value);
              }}
              onFocus={() => {
                // Track "Selected Chat"
                mixpanel.track("Selected Chat");
                setIsUserTyping(true);
                setDisplayText("");
              }}
              onKeyDown={handleKeyDown}
              placeholder={
                placeholderVersions[version]?.placeholder ||
                placeholderVersions["1"].placeholder
              }
              className="w-full h-32 bg-gray-800 text-gray-400 px-4 py-2 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 resize-none pr-10"
            />
            <button
              onClick={handlePromptSubmit}
              className="absolute bottom-4 right-4 bg-[#E64428] text-white p-2 rounded-md hover:bg-[#cc3922] focus:outline-none focus:ring-2 focus:ring-[#cc3922] m-2"
            >
              <PaperAirplaneIcon className="w-5 h-5" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
