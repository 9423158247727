import React from "react";

const TypingIndicator = () => {
  const styles = {
    typingIndicator: {
      display: "flex",
      alignItems: "center",
    },
    dot: {
      width: "8px",
      height: "8px",
      margin: "0 2px",
      backgroundColor: "#555",
      borderRadius: "50%",
      display: "inline-block",
      opacity: 0.3,
      animation: "typing 1.5s infinite ease-in-out",
    },
  };

  const keyframes = `
    @keyframes typing {
      0%, 60%, 100% {
        opacity: 0.3;
      }
      30% {
        opacity: 1;
      }
    }
  `;

  return (
    <div style={styles.typingIndicator}>
      <style>{keyframes}</style>
      <span style={{ ...styles.dot, animationDelay: "0s" }}></span>
      <span style={{ ...styles.dot, animationDelay: "0.3s" }}></span>
      <span style={{ ...styles.dot, animationDelay: "0.6s" }}></span>
    </div>
  );
};

export default TypingIndicator;
