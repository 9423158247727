import React from "react";

const CardAnimationComponent_Component = ({ onClick, children }) => {
  // Debug logging for component lifecycle
  React.useEffect(() => {
    console.log("CardAnimationComponent mounted");
    return () => {
      console.log("CardAnimationComponent unmounted");
    };
  }, []);

  return (
    <div
      className={`
        relative
        w-full 
        block 
        h-auto 
        overflow-visible 
        bg-transparent 
        transition-all 
        duration-500 
        ease-out
        fade-in
        slide-in
      `}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default CardAnimationComponent_Component;
