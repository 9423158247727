// HomePageContent_Component.jsx

import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useMixpanel } from "react-mixpanel-browser";

// Import components that make up your home page content
import HeadingSection_Component from "./HeadingSection_Component.jsx";
import LimitlessPossibilitiesSection_Component from "./LimitlessPossibilitiesSection_Component.jsx";
import CaseStudy_Component from "./CaseStudy_Component.jsx";
import SignUpForm_Component from "./SignUpForm_Component.jsx";
import CaseStudyVideo_Component from "./CaseStudyVideo_Component.jsx";
import SeeInActionVideo_Component from "./SeeInActionVideo_Component.jsx";

export default function HomePageContent_Component() {
  const mixpanel = useMixpanel();

  // Grab ?version= from the URL
  const [searchParams] = useSearchParams();
  const version = searchParams.get("version") || "1";

  // Track "Page Viewed" event when the component is mounted
  useEffect(() => {
    mixpanel.register({ version });
    mixpanel.track("Page Viewed", { page: "Homepage", version });
  }, [mixpanel]);

  // Configure which sections show for each version
  const versionConfig = {
    1: {
      showHeadingSection: true,
      showSeeInActionVideo: true,
      showLimitlessPossibilities: true,
      showCaseStudyVideo: true,
      showCaseStudy: true,
      showSignUpForm: true,
    },
    2: {
      showHeadingSection: true,
      showSeeInActionVideo: true, // Hide see-in-action video for version 2
      showLimitlessPossibilities: false, // Hide limitless possibilities
      showCaseStudyVideo: false,
      showCaseStudy: false,
      showSignUpForm: true,
    },
    // Add more versions if needed
  };

  // Deconstruct the booleans for the current version or fall back to version 1
  const {
    showHeadingSection,
    showSeeInActionVideo,
    showLimitlessPossibilities,
    showCaseStudyVideo,
    showCaseStudy,
    showSignUpForm,
  } = versionConfig[version] || versionConfig["1"];

  return (
    <>
      {/* HeadingSection_Component */}
      {showHeadingSection && <HeadingSection_Component />}

      {/* SeeInActionVideo_Component */}
      {showSeeInActionVideo && (
        <section className="relative -mt-10 z-10">
          <SeeInActionVideo_Component />
        </section>
      )}

      {/* LimitlessPossibilitiesSection_Component */}
      {showLimitlessPossibilities && (
        <section className="my-20 sm:my-40">
          <LimitlessPossibilitiesSection_Component />
        </section>
      )}

      {/* CaseStudyVideo_Component */}
      {showCaseStudyVideo && <CaseStudyVideo_Component />}

      {/* SignUpForm_Component */}
      {showSignUpForm && <SignUpForm_Component />}
    </>
  );
}
