import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProjectData from "./ProjectData_JsonFile.json";
import { getSolutionMetaData } from "./network/clients/solutionCloneMetaData";

// IMPORTANT: The store key for this slice is the same as the name: global. You should use this when accessing state related to this slice.

const initialState = {
  globalProjectData: [],
  globalProjectDataLoading: false,
  globalProjectDataError: "",
};

/**
 * Load project data from JSON configuration
 * No parameters required
 */
export const globalLoadProjectData = createAsyncThunk(
  "global/loadProjectData",
  async (_, { rejectWithValue }) => {
    try {
      console.log("Loading project data from api...");

      // make api call to extract the data
      let projects = await getSolutionMetaData();

      console.log("Projects are: ", projects);
      // Validate project data structure
      const isValidData = projects.every((project) => {
        // Check required fields according to schema
        const hasRequiredFields =
          typeof project.id === "string" &&
          typeof project.formattedName === "string" &&
          typeof project.summary === "string" &&
          typeof project.category === "string" &&
          typeof project.username === "string";

        return hasRequiredFields;
      });

      if (!isValidData) {
        console.error(
          "Invalid project data: Missing or invalid required fields (id, projectName, description, category)"
        );
        throw new Error("Invalid project data structure");
      }

      return projects;
    } catch (error) {
      console.error("Error loading project data:", error);
      return rejectWithValue(error.message);
    }
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(globalLoadProjectData.pending, (state) => {
        console.log("Loading project data...");
        state.globalProjectDataLoading = true;
        state.globalProjectDataError = "";
      })
      .addCase(globalLoadProjectData.fulfilled, (state, action) => {
        console.log("Project data loaded successfully");
        state.globalProjectData = action.payload;
        state.globalProjectDataLoading = false;
      })
      .addCase(globalLoadProjectData.rejected, (state, action) => {
        console.error("Failed to load project data:", action.payload);
        state.globalProjectDataLoading = false;
        state.globalProjectDataError = action.payload;
      });
  },
});

export default globalSlice.reducer;
