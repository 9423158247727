import axios from "axios";

export const startSolution = async (mixPanelId, conversationStart) => {
  // if this is not dev, we need to call the backend
  if (import.meta.env.VITE_ENVIRONMENT !== "dev") {
    let body = {
      conversationStart: conversationStart,
    };
    const response = await axios.post(`/opensolution/` + mixPanelId, body);
    return response.data.solution.id;
  } else {
    console.log("In dev so generating random solution id");
    // this is dev so come up with a fake solution id and use it to create an empty solution object
    // the solution id must be a different random, unique guid each time
    const randomUniqueGuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );

    return randomUniqueGuid;
  }
};
