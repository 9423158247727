import axios from "axios";

export const getResponse = async (solutionId, conversationBlock, version) => {
  let body = {
    solutionId: solutionId,
    conversation: conversationBlock,
    version: version,
  };
  const response = await axios.post(`/openproductdirector/` + solutionId, body);
  return response.data;
};
