// ["MainLayout", "Component"]
import React from "react";

// IMPORTANT DO NOT CHANGE
// This page uses react-router V6 which does not have Switch
// END IMPORTANT

// Import react-router-dom v6
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Import your components
import NavigationBar_Component from "./NavigationBar_Component.jsx";
import Footer_Component from "./Footer_Component.jsx";
import BackgroundImage from "./BackgroundImage_Image.webp";

// Import the newly created HomePageContent_Component
import HomePageContent_Component from "./HomePageContent_Component.jsx";

// Import your Legal_Component
import Legal_Component from "./Legal_Component.jsx";
import Pricing_Component from "./Pricing_Component.jsx";
import RedirectPage_Component from "./Redirect.jsx";
import TheOriginalsPage_Component from "./TheOriginalsPage_Component.jsx";

// This page is currently used to provide structure and layout to the overall site
export default function MainLayout_Component() {
  return (
    <BrowserRouter>
      <div className="relative min-h-screen overflow-y-auto bg-[#000000]">
        {/* Full-page background image */}
        <div
          className="absolute inset-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `
  linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 60%,
    #000 100%
  ),
  url(${BackgroundImage})
`,
            height: "100vh",
          }}
        />

        {/* Navigation Bar (always displayed) */}
        <NavigationBar_Component />

        {/* Main content area where we render different pages based on the route */}
        <div className="relative">
          <Routes>
            {/* HOME PAGE: path="/" */}
            <Route path="/" element={<HomePageContent_Component />} />

            {/* LEGAL PAGE: path="/legal" */}
            <Route path="/legal" element={<Legal_Component />} />

            <Route path="/pricing" element={<Pricing_Component />} />
            <Route path="/originals" element={<TheOriginalsPage_Component />} />
            <Route path="/producthunt" element={<RedirectPage_Component />} />
          </Routes>
        </div>

        {/* Footer (always displayed) */}
        <div className="z-50 bg-[#363638] flex items-center p-4 relative rounded-tl-[40px] rounded-tr-[40px]">
          <Footer_Component />
        </div>
      </div>
    </BrowserRouter>
  );
}
